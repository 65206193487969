import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 297.58 425.2"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<defs><clipPath id=\"i676500950__a\" transform=\"translate(-123.74 -122.25)\"><path fill=\"none\" d=\"M0 0h544.25v668.98H0z\"></path></clipPath></defs><g clip-path=\"url(#i676500950__a)\"><path fill=\"#00478d\" fill-rule=\"evenodd\" d=\"M107.26 23.36V9.9L81.84 0v315.32C35.12 319.65.04 342.84.04 370.2c0 30.39 42.3 55 94.52 55s94.51-24.6 94.51-55c0-27.36-35.39-50.52-81.81-54.88V145.24h190.32V23.36zm55.26 346.88c0 19-30.46 34.45-68 34.45s-68.05-15.41-68.05-34.45 30.41-34.49 67.98-34.49 68 15.42 68 34.47\"></path><path fill=\"#00478d\" d=\"M50.09 273.14c0-4.84-3.56-6.31-7.8-6.31h-8.03v20.74h4.58v-8.3h2.27l4.32 8.3h5.49l-5.27-8.75c2.93-.53 4.46-2.79 4.46-5.68m-8.59 2.28h-2.68v-4.74h3c1.67 0 3.54.33 3.54 2.42 0 2.26-2.17 2.32-3.86 2.32\"></path><path fill=\"#00478d\" d=\"M41.93 257.16a20.37 20.37 0 1 0 20.33 20.37 20.37 20.37 0 0 0-20.34-20.37m0 36.72a16.35 16.35 0 1 1 16.34-16.35 16.38 16.38 0 0 1-16.32 16.35\"></path><path fill=\"#fff\" fill-rule=\"evenodd\" d=\"M107.26 68.75h51.76v-41.4h-51.76zm82.8 0h103.52v-41.4H190.02zm-82.8 72.49h51.76V99.81h-51.76zm82.8 0h103.52V99.81H190.02z\"></path></g>", 2)
  ])))
}
export default { render: render }