import type {
  BillingAddressUpdate,
  DeliveryAddressUpdate,
} from '@ruokaboksi/api-client';
import { defaultCountry } from '@ruokaboksi/api-client';

const DEFAULT_BILLING_ADDRESS: BillingAddressUpdate = {
  addressLine: '',
  city: '',
  country: defaultCountry,
  postalCode: '',
} as const;

const DEFAULT_DELIVERY_ADDRESS: DeliveryAddressUpdate = {
  addressLine: '',
  city: '',
  country: defaultCountry,
  deliveryNotes: '',
  firstName: '',
  lastName: '',
  phone: null,
  postalCode: '',
} as const;

const SELECTOR_WEEKS_AMOUNT = 5;

export {
  DEFAULT_BILLING_ADDRESS,
  DEFAULT_DELIVERY_ADDRESS,
  SELECTOR_WEEKS_AMOUNT,
};
