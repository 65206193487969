import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 35 35"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "var(--icon-heart, #E07A7C)",
      d: "m17.826 16.977-.325.32-.327-.32a3.243 3.243 0 0 0-4.557 0 3.106 3.106 0 0 0 0 4.476L17.5 26.25l4.884-4.797a3.105 3.105 0 0 0 0-4.476 3.245 3.245 0 0 0-4.56 0"
    }, null, -1),
    _createElementVNode("path", {
      fill: "var(--icon-heart-stroke, currentColor)",
      d: "M31.655 16.469 18.53 3.344a1.457 1.457 0 0 0-2.062 0L3.343 16.469a1.459 1.459 0 0 0 1.031 2.489h1.458v10.208a2.92 2.92 0 0 0 2.917 2.917h17.5a2.92 2.92 0 0 0 2.917-2.917V18.958h1.458a1.458 1.458 0 0 0 1.031-2.49M26.25 29.166H8.75V15.188l8.75-8.75 8.75 8.75v6.687z"
    }, null, -1)
  ])))
}
export default { render: render }