import posthog from 'posthog-js';
import type { VNode } from 'vue';

/**
 * This component does three things:
 *
 * 1. Evaluates which scripts will be loaded based on customer preferences.
 * 2. Disables Sentry if the analytics consent has not been given.
 * 3. Opt-ins to PostHog if the analytics consent has been given.
 * 4. Establishes a route guard to send page views whenever the
 * route changes, provided the customer has consented to marketing.
 */
export default defineComponent({
  name: 'EvaluateScripts',
  setup() {
    const route = useRoute();
    const needsData = computed<boolean>(
      () => ![...USER_REQUIRED_WHITELIST, '/sign-out'].includes(route.path)
    );
    if (!needsData.value) {
      return { needsData };
    }

    const { isFetchedCurrentCustomer } = useCurrentCustomer();
    const isLoading = computed<boolean>(() => !isFetchedCurrentCustomer.value);

    const { checkCustobarStatus, sendPageView } = useCustobar();
    const { $sentryDisable } = useNuxtApp();
    const { hasAnalyticsConsent, hasMarketingConsent, hasPreferencesConsent } =
      useTrackingConsent();
    const { checkUserGuidingStatus } = useUserGuiding();

    const router = useRouter();
    // Skips page view if the customer has not consented to marketing
    router.afterEach(sendPageView);

    onMounted(() => {
      if (!needsData.value) return;
      // Disable Sentry after mounting if no consent
      watchEffect(() => {
        if (hasAnalyticsConsent.value === false) {
          posthog.opt_out_capturing();
          $sentryDisable();
        }
        if (hasAnalyticsConsent.value === true) {
          posthog.opt_in_capturing();
        }
      });
    });

    watch([isFetchedCurrentCustomer, hasMarketingConsent], checkCustobarStatus);
    watch(
      [isFetchedCurrentCustomer, hasPreferencesConsent],
      checkUserGuidingStatus
    );

    return { isLoading, needsData };
  },
  render(): VNode {
    return <>{this.$slots.default?.()}</>;
  },
});
