import type { NoticeType } from '@/components/Notices/Notice';

/** Time given to display the notification */
const NOTICE_TIMEOUT_DEFAULT = 6000;
const NOTICE_TYPE_DEFAULT: NoticeType = 'neutral';
const NOTICE_DISMISSABLE_DEFAULT = false;

export {
  NOTICE_TIMEOUT_DEFAULT,
  NOTICE_TYPE_DEFAULT,
  NOTICE_DISMISSABLE_DEFAULT,
};
