/**
 * Middleware to handle and set the customer's language.
 *
 * This middleware first checks for language in several locations:
 * 1. A language query parameter.
 * 2. A locale cookie.
 * 3. If neither is found, it attempts to fetch the language preference of the currently logged-in customer from the API.
 */

import type { Customer, Language } from '@ruokaboksi/api-client';
import { allLanguages, defaultLanguage } from '@ruokaboksi/api-client';

export default defineNuxtRouteMiddleware(async (to) => {
  const { $i18n } = useNuxtApp();
  const locale = $i18n.locale.value;
  const localeCookie = useCookie('locale');
  const queryLanguage = to.query.language;

  const setLocale = $i18n.setLocale;

  if (
    queryLanguage &&
    typeof queryLanguage === 'string' &&
    allLanguages.includes(queryLanguage as Language)
  ) {
    await setLocale(queryLanguage);
    localeCookie.value = queryLanguage;
    return;
  }

  if (
    localeCookie.value &&
    (!locale || localeCookie.value !== locale) &&
    allLanguages.includes(localeCookie.value as Language)
  ) {
    await setLocale(localeCookie.value);
    return;
  }
  if (!localeCookie.value) {
    // TODO: Enable server side data fetching for this to take effect
    const customer = await fetchCustomer();
    if (customer) {
      await setLocale(customer.language);
      localeCookie.value = customer.language;
    } else {
      const { host } = useRequestURL();
      const matchedLocale = $i18n.locales.value.find(
        (locale) => locale.domain === host && 'domainDefault' in locale
      );
      await setLocale(matchedLocale?.code ?? defaultLanguage);
      localeCookie.value = matchedLocale?.code ?? defaultLanguage;
    }
  }
});

const fetchCustomer = async (): Promise<Customer | null> => {
  const { $queryClient, $loggedInUser } = useNuxtApp();

  if (import.meta.server || !$loggedInUser.value) {
    return null;
  }

  const apiClient = useApiClient();
  const cachedCustomer = $queryClient?.getQueryData<Customer>([
    'myCustomerInfo',
  ]);

  if (cachedCustomer) {
    return cachedCustomer;
  }

  try {
    return await apiClient.customers.retrieve({ customerId: '_me' });
  } catch (_error) {
    return null;
  }
};
