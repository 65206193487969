/**
 * Middleware to preserve specific query parameters (defined in PASSABLE_QUERY_KEYS) across route changes in a Nuxt app.
 *
 * It checks for allowed parameters in the previous route and adds them to the current route if missing.
 */
import type { RouteLocation } from 'vue-router';

// Define the allowed query keys
const PASSABLE_QUERY_KEYS = ['from'];

// Helper function to check if there are allowed query parameters to add
function shouldAddAllowedQueryParams(
  currentQuery: RouteLocation['query'],
  previousQuery: RouteLocation['query']
) {
  return PASSABLE_QUERY_KEYS.some(
    (key) => previousQuery[key] && !currentQuery[key]
  );
}

// Helper function to filter and return allowed query parameters
function filterAllowedQueryParams(
  toQuery: RouteLocation['query'],
  fromQuery: RouteLocation['query']
) {
  const newQuery = { ...toQuery };
  PASSABLE_QUERY_KEYS.forEach((key) => {
    if (fromQuery[key] && !toQuery[key]) {
      newQuery[key] = fromQuery[key];
    }
  });
  return newQuery;
}

// Updated middleware
export default defineNuxtRouteMiddleware((to, from) => {
  // Only run on client-side navigation
  if (import.meta.server) {
    return;
  }

  // Only run if the path has changed
  if (to.path === from.path) {
    return;
  }

  if (shouldAddAllowedQueryParams(to.query, from.query)) {
    const newQuery = filterAllowedQueryParams(to.query, from.query);
    return navigateTo({ path: to.path, query: newQuery });
  }
});
