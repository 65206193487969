import { Temporal } from 'temporal-polyfill';
import { format } from 'date-fns';
import { fi, cs, enUS } from 'date-fns/locale';
import { marketSchemas } from '@ruokaboksi/api-client';

// src/date/Week.ts

// src/date/config.ts
var defaultBaseTimeZone = "Europe/Helsinki";
var defaultDeliveryHour = 12;
var defaultPaymentHour = 12;

// src/date/exceptions.ts
var DateParseError = class extends Error {
};
var InvalidDateError = class extends Error {
};
var InvalidIsoWeekError = class extends Error {
};
var WeekDayMap = {
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
  SUNDAY: 7
};
var nextWeekdayOccurrenceDate = (targetDay, fromDate = new Date(
  Temporal.Now.zonedDateTimeISO("UTC").epochMilliseconds
), baseTimeZone = "UTC", skipIfSameDay = true) => {
  const targetDayInt = WeekDayMap[targetDay];
  let date = Temporal.Instant.fromEpochMilliseconds(
    fromDate.getTime()
  ).toZonedDateTimeISO(baseTimeZone);
  if (skipIfSameDay) {
    date = date.add({ days: 1 });
  }
  while (date.dayOfWeek !== targetDayInt) {
    date = date.add({ days: 1 });
  }
  return new Date(date.epochMilliseconds);
};
var getWeekDayByNumber = (weekdayNumber) => {
  const entries = Object.entries(WeekDayMap);
  const foundEntry = entries.find(([_, num]) => num === weekdayNumber);
  if (!foundEntry) {
    throw new Error(
      `Invalid weekday number: ${weekdayNumber}. Valid numbers are 1 to 7.`
    );
  }
  return foundEntry[0];
};

// src/date/Week.ts
var PREVIOUS_WEEK_DELIVERY_DAYS = /* @__PURE__ */ new Set([
  WeekDayMap["SUNDAY"],
  WeekDayMap["SATURDAY"]
]);
var Week = class _Week {
  constructor(isoYear, isoWeek, deliverySlot, baseTimeZone = _Week.defaultBaseTimeZone) {
    this.isoYear = isoYear;
    this.isoWeek = isoWeek;
    this.deliverySlot = deliverySlot;
    this.baseTimeZone = baseTimeZone;
    try {
      Temporal.TimeZone.from(baseTimeZone);
    } catch {
      throw new Error(`Invalid time zone: ${baseTimeZone}`);
    }
    const deliveryDate = this.deliveryDate();
    const baseDeliveryDate = _Week.DateToDateTime(deliveryDate, baseTimeZone);
    const weekNumberAfterAdding = this.isPreviousWeekDelivery() ? baseDeliveryDate.add({ weeks: 1 }).weekOfYear : baseDeliveryDate.weekOfYear;
    if (weekNumberAfterAdding !== isoWeek) {
      throw new InvalidIsoWeekError(
        `Invalid week: ${isoWeek} for delivery day ${this.isPreviousWeekDelivery() ? "falling on previous week" : ""}`
      );
    }
  }
  /**
   * Initialize from ISO year / ISO week pair.
   *
   * @param isoYear First half of (ISO year, ISO week) pair.
   *  Note that ISO year is different from calendar year as ISO year
   *  is related to the ISO week. For example, Sunday 2023-01-01 corresponds
   *  to ISO year-week 2022-52 and thus has isoYear=2022.
   * @param isoWeek Second half of (ISO year, ISO week) pair.
   */
  static defaultBaseTimeZone = defaultBaseTimeZone;
  /**
   * Determines if the delivery slot's day is one that is considered
   * part of the delivery week of the following calendar week.
   */
  isPreviousWeekDelivery() {
    const deliveryDayInt = WeekDayMap[this.deliverySlot.deliveryDay];
    return PREVIOUS_WEEK_DELIVERY_DAYS.has(deliveryDayInt);
  }
  static DateToDateTime(date, baseTimeZone = _Week.defaultBaseTimeZone) {
    return Temporal.Instant.fromEpochMilliseconds(
      date.getTime()
    ).toZonedDateTimeISO(baseTimeZone);
  }
  static DateTimeToDate(temporalZonedDateTime) {
    return new Date(temporalZonedDateTime.epochMilliseconds);
  }
  static fromNow(deliverySlot, baseTimeZone = _Week.defaultBaseTimeZone) {
    const baseDate = Temporal.Now.zonedDateTimeISO(baseTimeZone);
    return new _Week(
      baseDate.year,
      Number(baseDate.weekOfYear),
      deliverySlot,
      baseTimeZone
    );
  }
  /**
   * Return a Week from the given delivery date, with support for delivery
   * days on the previous week.
   */
  static fromDeliveryDate(deliveryDate, deliverySlot, baseTimeZone = _Week.defaultBaseTimeZone) {
    const baseDeliveryDate = _Week.DateToDateTime(deliveryDate, baseTimeZone);
    this.validateDayOfWeek(baseDeliveryDate, deliverySlot.deliveryDay);
    let weekAdjustment = 0;
    if (PREVIOUS_WEEK_DELIVERY_DAYS.has(baseDeliveryDate.dayOfWeek)) {
      weekAdjustment = 1;
    }
    const adjustedBaseDeliveryDate = baseDeliveryDate.add({
      weeks: weekAdjustment
    });
    return new _Week(
      Number(adjustedBaseDeliveryDate.yearOfWeek),
      Number(adjustedBaseDeliveryDate.weekOfYear),
      deliverySlot,
      baseTimeZone
    );
  }
  static fromPayment(baseDate, deliverySlotDay) {
    let possibleBaseDate = baseDate.add({ days: 1 });
    while (possibleBaseDate.dayOfWeek !== WeekDayMap[deliverySlotDay]) {
      possibleBaseDate = possibleBaseDate.add({ days: 1 });
    }
    return possibleBaseDate;
  }
  /**
   * Return a Week from the given payment date.
   */
  static fromPaymentDate(paymentDate, deliverySlot, baseTimeZone = _Week.defaultBaseTimeZone) {
    const basePaymentDate = _Week.DateToDateTime(paymentDate, baseTimeZone);
    this.validateDayOfWeek(basePaymentDate, deliverySlot.paymentDay);
    const date = this.fromPayment(basePaymentDate, deliverySlot.deliveryDay);
    return _Week.fromDeliveryDate(
      _Week.DateTimeToDate(date),
      deliverySlot,
      baseTimeZone
    );
  }
  static fromFirstFuturePaymentDate(deliverySlot, baseTimeZone = _Week.defaultBaseTimeZone) {
    const baseCurrentDate = Temporal.Now.zonedDateTimeISO(baseTimeZone);
    const date = this.fromPayment(baseCurrentDate, deliverySlot.paymentDay);
    return _Week.fromPaymentDate(
      _Week.DateTimeToDate(date),
      deliverySlot,
      baseTimeZone
    );
  }
  /**
   * Parse ISO 8601 week string (yyyy-Www).
   * In addition to the canonical format, the "W" may be
   * absent and there may be between 1 and 3 week digits.
   *
   * @example "2023-W05"
   * @example "2023-5"
   * @throw InvalidIsoWeekError If parsing fails.
   */
  static fromYyyyWww(yyyyWww, deliverySlot) {
    const RE_ISO_8601 = /^([0-9]{4})-W?([0-9]{1,3})$/;
    const match = RE_ISO_8601.exec(yyyyWww);
    if (match === null) {
      throw new InvalidIsoWeekError(
        `Cannot parse ISO 8601 week string yyyy-Www: ${yyyyWww}`
      );
    }
    const year = parseInt(match[1] ?? "-1");
    const week = parseInt(match[2] ?? "-1");
    return new _Week(year, week, deliverySlot);
  }
  /**
   * Format the week as ISO 8601 week. This is compatible with `<input type="week" />`.
   * @example '2023-W04'
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/week
   * @see https://en.wikipedia.org/wiki/ISO_8601#Week_dates
   */
  toYyyyWww() {
    const weekFormatted = this.isoWeek.toString().padStart(2, "0");
    return `${this.isoYear}-W${weekFormatted}`;
  }
  /**
   * Return a new week with weekDelta weeks added
   * (positive) or subtracted (negative).
   * @param weekDelta Integer amount of weeks to add or subtract.
   *   Can be positive, zero or negative.
   */
  withAddWeeks(weekDelta) {
    const originalDeliveryDate = this.deliveryDate();
    const originalDeliveryDateTime = _Week.DateToDateTime(
      originalDeliveryDate,
      this.baseTimeZone
    );
    const newDeliveryDateTemporal = originalDeliveryDateTime.add({
      weeks: weekDelta
    });
    const newDeliveryDate = _Week.DateTimeToDate(newDeliveryDateTemporal);
    return _Week.fromDeliveryDate(newDeliveryDate, this.deliverySlot);
  }
  /**
   * True if both weeks represent the same 7-day interval.
   */
  equals(other) {
    return this.isoYear === other.isoYear && this.isoWeek === other.isoWeek;
  }
  /**
   * Return 1 if this > other, 0 if this == other and -1 if this < other.
   */
  compare(other) {
    const thisPaymentDateTime = _Week.DateToDateTime(
      this.paymentDate(),
      this.baseTimeZone
    );
    const otherPaymentDateTime = _Week.DateToDateTime(
      other.paymentDate(),
      other.baseTimeZone
    );
    if (thisPaymentDateTime.equals(otherPaymentDateTime)) {
      return 0;
    } else if (thisPaymentDateTime.epochMilliseconds > otherPaymentDateTime.epochMilliseconds) {
      return 1;
    } else {
      return -1;
    }
  }
  /**
   * Return the difference between this and other as number of weeks.
   *
   * The difference is this - other, meaning the difference is:
   * - positive if this > other
   * - zero if this == other
   * - negative if this < other.
   *
   * The interpretation is: how many weeks later is 'this' compared
   * to 'other'?
   *
   * Invariant: sign(this.differenceWeek(other)) == this.compare(other)
   * Return the difference between two weeks in weeks.
   */
  differenceWeeks(other) {
    const thisPaymentDate = this.paymentDate();
    const otherPaymentDate = other.paymentDate();
    const thisPaymentDateTime = _Week.DateToDateTime(
      thisPaymentDate,
      this.baseTimeZone
    );
    const otherPaymentDateTime = _Week.DateToDateTime(
      otherPaymentDate,
      other.baseTimeZone
    );
    return otherPaymentDateTime.until(thisPaymentDateTime, {
      largestUnit: "week"
    }).weeks;
  }
  /**
   * Return a delivery date for the given week day.
   * The week day is interpreted in the baseTimeZone, and the returned
   * date is set to the start of the day (00:00:00.000) in UTC.
   *
   * @param weekDay The week day string to return. MONDAY, TUESDAY, etc.
   */
  deliveryDate() {
    const weekDayInt = WeekDayMap[this.deliverySlot.deliveryDay];
    const adjustWeeks = this.isPreviousWeekDelivery() ? -1 : 0;
    const januaryFourthOnIsoYear = Temporal.PlainDate.from({
      year: this.isoYear,
      month: 1,
      day: 4,
      calendar: "iso8601"
    });
    const mondayOfFirstIsoWeek = januaryFourthOnIsoYear.subtract({
      days: januaryFourthOnIsoYear.dayOfWeek - 1
    });
    const weeks = this.isoWeek - 1 + adjustWeeks;
    const duration = Temporal.Duration.from({
      days: weeks * 7 + weekDayInt - 1
    });
    let deliveryDateTime = mondayOfFirstIsoWeek;
    deliveryDateTime = mondayOfFirstIsoWeek.add(duration);
    const plainDateTime = Temporal.PlainDateTime.from({
      year: deliveryDateTime.year,
      month: deliveryDateTime.month,
      day: deliveryDateTime.day,
      hour: defaultDeliveryHour
    });
    const zonedDateTime = plainDateTime.toZonedDateTime(this.baseTimeZone);
    return _Week.DateTimeToDate(zonedDateTime);
  }
  /** Returns the payment date for a given week. */
  paymentDate(hour = defaultPaymentHour) {
    const deliveryDateInBaseTimeZone = _Week.DateToDateTime(
      this.deliveryDate(),
      this.baseTimeZone
    );
    const paymentDayInt = WeekDayMap[this.deliverySlot.paymentDay];
    let possiblePaymentDate = deliveryDateInBaseTimeZone.subtract({ days: 1 });
    while (possiblePaymentDate.dayOfWeek !== paymentDayInt) {
      possiblePaymentDate = possiblePaymentDate.subtract({ days: 1 });
    }
    const paymentDate = possiblePaymentDate.with({
      hour,
      minute: 0,
      second: 0,
      millisecond: 0
    });
    return _Week.DateTimeToDate(paymentDate);
  }
  static validateDayOfWeek(zonedDateTime, expectedDay) {
    if (zonedDateTime.dayOfWeek !== WeekDayMap[expectedDay]) {
      throw new InvalidIsoWeekError(
        `Date ${zonedDateTime.toPlainDate().toString()} is a ${getWeekDayByNumber(
          zonedDateTime.dayOfWeek
        )} and does not match expected day ${expectedDay}`
      );
    }
  }
};

// src/date/WeekRange.ts
var WeekRange = class _WeekRange {
  constructor(startWeek, weekCount) {
    this.startWeek = startWeek;
    this.weekCount = weekCount;
    if (weekCount < 0) {
      throw new InvalidIsoWeekError("weekCount must be >= 0");
    }
  }
  /**
   * Construct a WeekRange from a pair (startWeek, endWeek) where
   * startWeek is part of the week but endWeek is not (half-open interval).
   * The last included week is endWeekExclusive - 1.
   *
   * If startWeekInclusive >= endWeekExclusive, then weekCount == 0.
   */
  static fromWeekPairHalfOpen(startWeekInclusive, endWeekExclusive) {
    const difference = Math.max(
      -startWeekInclusive.differenceWeeks(endWeekExclusive),
      0
    );
    return new _WeekRange(startWeekInclusive, difference);
  }
  /**
   * Return the array of Week objects in order.
   * Invariant: getWeeks().length == weekCount.
   */
  getWeeks() {
    if (this.weekCount === 0) {
      return [];
    }
    const weekDeltas = Array.from(Array(this.weekCount).keys());
    return weekDeltas.map((i) => this.startWeek.withAddWeeks(i));
  }
  /**
   * Convenience method to return the first week of the range.
   * This is the first element of getWeeks().
   * If weekCount is 0, return null.
   */
  getFirstWeek() {
    if (this.weekCount === 0) return null;
    return this.startWeek;
  }
  /**
   * Convenience method to return the last week of the range.
   * This is the last element of getWeeks().
   * If weekCount is 0, return null.
   */
  getLastWeek() {
    if (this.weekCount === 0) return null;
    return this.startWeek.withAddWeeks(this.weekCount - 1);
  }
  /**
   * Return the index (0..n-1) of queryWeek within this range.
   * Return -1 if queryWeek is not included in this range.
   * The actual week can be obtained using range.getWeeks()[index]
   * (after verifying index >= 0).
   */
  findWeekIndex(queryWeek) {
    return this.getWeeks().findIndex((w) => w.equals(queryWeek));
  }
  /**
   * Return true if the given week is included within this range.
   * Return false if it is either before or after this range.
   */
  isWeekIncluded(queryWeek) {
    return this.findWeekIndex(queryWeek) >= 0;
  }
};
var PlainDate = class _PlainDate {
  date;
  baseTimeZone;
  constructor(year, month, day, baseTimeZone = defaultBaseTimeZone) {
    this.baseTimeZone = baseTimeZone;
    try {
      this.date = Temporal.PlainDate.from(
        { year, month, day },
        { overflow: "reject" }
      );
    } catch {
      throw new Error(`Invalid date`);
    }
  }
  toJSDate() {
    const zonedDateTime = this.date.toZonedDateTime({
      timeZone: this.baseTimeZone,
      plainTime: Temporal.PlainTime.from({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
      })
    });
    return new Date(zonedDateTime.epochMilliseconds);
  }
  static now(baseTimeZone = defaultBaseTimeZone) {
    const now = Temporal.Now.plainDateISO(baseTimeZone);
    return new _PlainDate(now.year, now.month, now.day, baseTimeZone);
  }
  isInFuture() {
    const zone = this.baseTimeZone;
    const today = Temporal.Now.plainDateISO(zone);
    return Temporal.PlainDate.compare(this.date, today) > 0;
  }
};
var PlainWeek = class _PlainWeek {
  date;
  baseTimeZone;
  /**
   * Initializes a new instance of PlainWeek set to the start of the specified week.
   * @param year The ISO week year.
   * @param weekNumber The ISO week number.
   * @param baseTimeZone The time zone to consider for the week calculations.
   */
  constructor(year, weekNumber, baseTimeZone = defaultBaseTimeZone) {
    this.baseTimeZone = baseTimeZone;
    const maxWeeks = Temporal.PlainDate.from({
      year,
      month: 12,
      day: 28
    }).weekOfYear;
    if (weekNumber < 1 || weekNumber > Number(maxWeeks)) {
      throw new Error(`Invalid week number: ${weekNumber} for year: ${year}`);
    }
    const januaryFourthOnIsoYear = Temporal.PlainDate.from({
      year,
      month: 1,
      day: 4,
      // January 4th is always in the first ISO week of the year
      calendar: "iso8601"
    });
    const mondayOfFirstIsoWeek = januaryFourthOnIsoYear.subtract({
      days: januaryFourthOnIsoYear.dayOfWeek - 1
    });
    this.date = mondayOfFirstIsoWeek.add({ weeks: weekNumber - 1 });
  }
  /**
   * Creates a new PlainWeek instance representing the current week in the specified base time zone.
   * @param baseTimeZone The time zone to consider for the week calculations.
   */
  static now(baseTimeZone = defaultBaseTimeZone) {
    const now = Temporal.Now.plainDateISO();
    const zonedDateTime = now.toZonedDateTime({ timeZone: baseTimeZone });
    return new _PlainWeek(
      Number(zonedDateTime.yearOfWeek),
      Number(zonedDateTime.weekOfYear),
      baseTimeZone
    );
  }
  /**
   * Determines if the PlainWeek instance represents a week in the future,
   * relative to the current week in the same base time zone.
   */
  isInFuture() {
    const now = Temporal.Now.plainDateISO(this.baseTimeZone);
    return Temporal.PlainDate.compare(this.date, now) > 0;
  }
  /**
   * Creates a new PlainWeek instance by adding a specified number of weeks to the current instance.
   * This method does not mutate the current instance but returns a new one.
   * @param n The number of weeks to add.
   */
  withAddWeeks(n) {
    const newDate = this.date.add({ weeks: n });
    return new _PlainWeek(
      Number(newDate.yearOfWeek),
      Number(newDate.weekOfYear),
      this.baseTimeZone
    );
  }
  /**
   * Creates a new PlainWeek instance from a string representing a week in the format "YYYY-WW".
   */
  static fromString(dateString, baseTimeZone = defaultBaseTimeZone) {
    const [year, week] = dateString.split("-").map((part) => parseInt(part, 10));
    return new _PlainWeek(year, week, baseTimeZone);
  }
  /**
   * Gets the ISO week number of the PlainWeek instance.
   */
  get isoWeek() {
    return Number(this.date.weekOfYear);
  }
  /**
   * Gets the ISO week year of the PlainWeek instance.
   */
  get isoYear() {
    return Number(this.date.yearOfWeek);
  }
};
var locales = {
  fi,
  cs,
  en: enUS
};
var formatDateToWeekNumberString = (date) => format(date, "I");
var formatDateWithoutTime = (date, onMissing = "") => {
  if (date === void 0) return onMissing;
  return format(date, "d.M.yyyy");
};
var formatDateWithTime = (date, localeString) => {
  const locale = locales[localeString];
  const formatString = localeString === "fi" ? "d.M.yyyy 'klo' HH:mm:ss" : "d.M.yyyy HH:mm:ss";
  return format(date, formatString, { locale });
};
var formatDayOfWeek2 = (dayOfWeek, localeString) => {
  if (dayOfWeek < 1 || dayOfWeek > 7) {
    throw new InvalidDateError(`Unknown day of week: ${dayOfWeek}`);
  }
  const locale = locales[localeString];
  const dummyDate = new Date(2024, 6, dayOfWeek);
  return format(dummyDate, "EEEEEE", { locale });
};
var formatDateToShortWeekDayDate = (date, localeString) => {
  const locale = locales[localeString];
  return format(date, "EEEEEE d.M", { locale });
};
var formatDeliverySlotTimeWindow = (deliverySlot) => {
  const formatTime = (time) => {
    const paddedTime = String(time).padStart(4, "0");
    const hours = paddedTime.slice(0, 2);
    const minutes = paddedTime.slice(2);
    return `${hours}:${minutes}`;
  };
  const startTime = formatTime(deliverySlot.startTime);
  const endTime = formatTime(deliverySlot.endTime);
  return `${startTime}-${endTime}`;
};
var makeStartOfDayUTC = (date) => {
  let inDate;
  try {
    if (date === void 0) {
      inDate = Temporal.Now.zonedDateTimeISO("UTC");
    } else if (typeof date === "string") {
      inDate = Temporal.Instant.fromEpochMilliseconds(
        new Date(date).getTime()
      ).toZonedDateTimeISO("UTC");
    } else if (date instanceof Date) {
      inDate = Temporal.Instant.fromEpochMilliseconds(
        date.getTime()
      ).toZonedDateTimeISO("UTC");
    } else if (typeof date === "number") {
      inDate = Temporal.Instant.fromEpochMilliseconds(date).toZonedDateTimeISO("UTC");
    } else {
      throw new InvalidDateError(`Invalid date: ${date}`);
    }
    const startOfDay = inDate.toPlainDate().toZonedDateTime({
      timeZone: "UTC",
      plainTime: Temporal.PlainTime.from({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
      })
    });
    return new Date(startOfDay.epochMilliseconds);
  } catch {
    throw new InvalidDateError(`Invalid date: ${date}`);
  }
};

// src/price/exceptions.ts
var InvalidLoyaltyLevelError = class extends Error {
};
var InvalidPriceError = class extends Error {
};
var InvalidPriceTierError = class extends Error {
};
var InvalidTaxRateError = class extends Error {
};

// src/price/internalUtils.ts
var validatePrice = (price) => {
  if (price < 0) {
    throw new InvalidPriceError("Invalid price: negative");
  }
  if (Math.round(price) !== price) {
    throw new InvalidPriceError(
      `Invalid price ${price}: must be integer cents`
    );
  }
};
var getPremiumRecipes = (recipes) => {
  return recipes.filter((recipe) => recipe.isPremium);
};

// src/price/Price.ts
var Price = class _Price {
  /**
   * Final price and main display price for consumer.
   */
  netPrice;
  /**
   * Gross price (in integer cents) with sales tax but without discounts or credits.
   */
  grossPrice;
  /**
   * Total discount amount (in integer cents) which is subtracted from grossPrice.
   * Discount can be composed of campaign discount or loyalty program discount.
   */
  discount;
  /**
   * Amount of credit used (in integer cents). Credit is considered as a
   * form of discount.
   */
  credit;
  constructor(components) {
    const { netPrice, grossPrice, discount, credit } = components;
    validatePrice(netPrice);
    validatePrice(grossPrice);
    validatePrice(discount);
    validatePrice(credit);
    if (netPrice !== grossPrice - discount - credit) {
      throw new InvalidPriceError(
        "netPrice must be = grossPrice - discount - credit"
      );
    }
    this.netPrice = netPrice;
    this.grossPrice = grossPrice;
    this.discount = discount;
    this.credit = credit;
  }
  static fromGrossPrice(grossPrice, discount = 0, credit = 0) {
    const netPrice = grossPrice - discount - credit;
    return new _Price({
      netPrice,
      grossPrice,
      discount,
      credit
    });
  }
  static fromNetPrice(netPrice, discount = 0, credit = 0) {
    const grossPrice = netPrice + discount + credit;
    return new _Price({ netPrice, grossPrice, discount, credit });
  }
  /**
   * Return true if the net price has been modified by discounts,
   * or false if the net price is the same as gross price.
   */
  hasPriceModifier = () => {
    return this.netPrice !== this.grossPrice;
  };
};
var ZERO_PRICE = Price.fromGrossPrice(0, 0, 0);

// src/price/campaign.ts
var isCampaignActive = (campaignLink, now) => {
  if (campaignLink === null) return false;
  const startWeek = new Week(
    campaignLink.startIsoYear,
    campaignLink.startIsoWeek,
    now.deliverySlot
  );
  const campaignRange = new WeekRange(
    startWeek,
    campaignLink.campaign.durationWeeks
  );
  return campaignRange.isWeekIncluded(now);
};
var calculateCampaignWeekIndex = (campaignLink, now) => {
  const startWeek = new Week(
    campaignLink.startIsoYear,
    campaignLink.startIsoWeek,
    now.deliverySlot
  );
  const campaignRange = new WeekRange(
    startWeek,
    campaignLink.campaign.durationWeeks
  );
  const weekIndex = campaignRange.findWeekIndex(now);
  if (weekIndex < 0) {
    return void 0;
  }
  if (weekIndex >= campaignLink.campaign.durationWeeks) {
    throw new Error("Internal error: weekIndex is larger than durationWeeks");
  }
  return weekIndex;
};
var resolveCampaignWeekItem = (campaignLink, now, weekItems) => {
  const weekIndex = calculateCampaignWeekIndex(campaignLink, now);
  if (weekIndex === void 0) return void 0;
  return weekItems[weekIndex];
};

// src/price/loyalty.ts
var MIN_LOYALTY_LEVEL = 1;
var MAX_LOYALTY_LEVEL = 4;
var parseLoyaltyLevel = (level) => {
  if (level < MIN_LOYALTY_LEVEL || level > MAX_LOYALTY_LEVEL) {
    throw new InvalidLoyaltyLevelError(`Invalid loyalty level: ${level}`);
  }
  return level;
};
var getLoyaltyDiscountPercentage = (level) => {
  switch (level) {
    case 1:
      return 0;
    case 2:
      return 2;
    case 3:
      return 4;
    case 4:
      return 8;
    default:
      throw new InvalidLoyaltyLevelError(`Invalid loyalty level: ${level}`);
  }
};
var getSuccessorLoyaltyLevel = (level) => {
  return parseLoyaltyLevel(Math.min(level + 1, MAX_LOYALTY_LEVEL));
};

// src/price/calculatePrice.ts
var resolvePriceTier = (priceTiers, recipeCount) => {
  const match = priceTiers.find((tier) => tier.recipeCount === recipeCount);
  if (void 0 === match) {
    throw new InvalidPriceTierError("Invalid number of recipes");
  }
  return match;
};
var calculateBoxPrice = (args) => {
  const { priceTier, campaign, now } = args;
  let campaignDiscount = 0;
  if (campaign?.campaign.campaignType === "boxDiscount") {
    const campaignWeekItem = resolveCampaignWeekItem(campaign, now, campaign.campaign.discounts) || 0;
    const availableCampaignDiscount = campaign.campaign.discountType === "percentage" ? priceTier.price * (campaignWeekItem / 100) : campaignWeekItem;
    campaignDiscount = Math.min(availableCampaignDiscount, priceTier.price);
  }
  const loyaltyDiscount = Math.round(
    priceTier.price * (getLoyaltyDiscountPercentage(args.loyaltyLevel) / 100)
  );
  const discount = Math.max(campaignDiscount, loyaltyDiscount);
  const credit = priceTier.price - discount - (args.credit ?? 0) < 0 ? 0 : args.credit;
  return Price.fromGrossPrice(priceTier.price, discount, credit);
};
var sum = (values) => {
  return values.reduce((x1, x2) => x1 + x2, 0);
};
var sumPrices = (prices) => {
  const netPrice = sum(prices.map((p) => p.netPrice));
  const grossPrice = sum(prices.map((p) => p.grossPrice));
  const discount = sum(prices.map((p) => p.discount));
  const credit = sum(prices.map((p) => p.credit));
  return new Price({ netPrice, grossPrice, discount, credit });
};
var calculateAdditionalProductsPrice = (args) => {
  const { additionals, now } = args;
  const activeCampaign = isCampaignActive(args.campaign, now) ? args.campaign : null;
  const appliedDiscounts = /* @__PURE__ */ new Set();
  const calculateDiscount = (campaign, product) => {
    if (campaign.campaign.campaignType !== "additionalProductDiscount" || campaign.campaign.additionalProductId !== product.id || appliedDiscounts.has(product.id)) {
      return 0;
    }
    let discount = 0;
    if (campaign.campaign.discountType === "percentage") {
      discount = campaign.campaign.discount / 100 * product.frozenProductPrice;
    } else {
      discount = Math.min(
        campaign.campaign.discount,
        product.frozenProductPrice
      );
    }
    appliedDiscounts.add(product.id);
    return discount;
  };
  const additionalPrices = additionals.flatMap((product) => {
    const quantity = product.quantity ?? 1;
    return Array(quantity).fill(product).map((productInstance) => {
      const discount = activeCampaign ? calculateDiscount(activeCampaign, productInstance) : 0;
      return Price.fromGrossPrice(
        productInstance.frozenProductPrice,
        discount
      );
    });
  });
  return sumPrices(additionalPrices);
};
var calculateAdditionalProductPrice = (args) => {
  const { additional } = args;
  return calculateAdditionalProductsPrice({
    ...args,
    additionals: [additional]
  });
};
var calculateDeliverySlotPrice = (deliverySlot) => {
  return Price.fromGrossPrice(deliverySlot.price, 0);
};
var calculateTax = (price, taxRate) => {
  validatePrice(price);
  if (taxRate < 0 || taxRate > 100) {
    throw new InvalidTaxRateError("Invalid tax rate");
  }
  return Math.round(price - price / (1 + taxRate / 100));
};
var calculateDeliveryPlanPrice = (args) => {
  const boxPrice = calculateBoxPrice(args);
  const premiumRecipesPrice = Price.fromGrossPrice(
    getPremiumRecipes(args.recipes).reduce((sum2, recipe) => {
      const price = recipe?.premiumPrice || 0;
      return sum2 + (typeof price === "number" ? price : 0);
    }, 0)
  );
  const additionalsPrice = calculateAdditionalProductsPrice(args);
  const deliverySlotPrice = calculateDeliverySlotPrice(args.deliverySlot);
  return sumPrices([
    boxPrice,
    premiumRecipesPrice,
    additionalsPrice,
    deliverySlotPrice
  ]);
};

// src/price/credit.ts
var allocateCredit = (priceWithoutCredit, startCredit) => {
  validatePrice(startCredit);
  if (priceWithoutCredit.credit !== 0) {
    throw new InvalidPriceError(
      "Cannot allocate credit when existing credit is != 0"
    );
  }
  const credit = Math.min(priceWithoutCredit.netPrice, startCredit);
  const priceWithCredit = Price.fromGrossPrice(
    priceWithoutCredit.grossPrice,
    priceWithoutCredit.discount,
    credit
  );
  const endCredit = startCredit - credit;
  validatePrice(endCredit);
  return { priceWithCredit, endCredit };
};

// src/price/formatPrice.ts
var currencyMap = {
  EUR: "\u20AC",
  CZK: "K\u010D"
};
var formatPriceWithoutCurrency = (price) => {
  const euros = price / 100;
  const isFullEuros = price % 100 === 0;
  if (isFullEuros) {
    return euros.toFixed(0);
  } else {
    return euros.toFixed(2).replace(".", ",");
  }
};
var formatPrice = (price, currency, onMissing = "") => {
  if (price === void 0) {
    return onMissing;
  }
  validatePrice(price);
  return formatPriceWithoutCurrency(price) + " " + currencyMap[currency];
};
var validateMarket = (market, language) => {
  const schema = marketSchemas[market];
  if (!schema) {
    throw new Error(`No schema found for market id: ${market}`);
  }
  const result = schema.parse({ market, language });
  return {
    market: result.market,
    language: result.language
  };
};

// src/i18n/middleware.ts
var validateAndParseLocaleMiddleware = (req, _, next) => {
  if (!Object.hasOwn(req.params, "market")) {
    return next();
  }
  if (!req.params.market) {
    return next({ status: 400, message: "Market is required" });
  }
  const marketRaw = req.params.market;
  const languageRaw = req.query.language;
  try {
    const { market, language } = validateMarket(marketRaw, String(languageRaw));
    req.market = market;
    req.language = language;
  } catch (error) {
    return next({
      status: 400,
      message: error.errors
    });
  }
  next();
};

export { DateParseError, InvalidDateError, InvalidIsoWeekError, InvalidLoyaltyLevelError, InvalidPriceError, InvalidPriceTierError, InvalidTaxRateError, MAX_LOYALTY_LEVEL, MIN_LOYALTY_LEVEL, PlainDate, PlainWeek, Price, Week, WeekDayMap, WeekRange, ZERO_PRICE, allocateCredit, calculateAdditionalProductPrice, calculateAdditionalProductsPrice, calculateBoxPrice, calculateDeliveryPlanPrice, calculateDeliverySlotPrice, calculateTax, currencyMap, formatDateToShortWeekDayDate, formatDateToWeekNumberString, formatDateWithTime, formatDateWithoutTime, formatDayOfWeek2, formatDeliverySlotTimeWindow, formatPrice, getLoyaltyDiscountPercentage, getSuccessorLoyaltyLevel, getWeekDayByNumber, isCampaignActive, makeStartOfDayUTC, nextWeekdayOccurrenceDate, parseLoyaltyLevel, resolveCampaignWeekItem, resolvePriceTier, sumPrices, validateAndParseLocaleMiddleware, validateMarket };
