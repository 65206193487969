import type { Customer } from '@ruokaboksi/api-client';

/**
 * Composable that provides information about the current customer.
 * @see useCustomerApi
 */
export default function useCurrentCustomer() {
  const { getMyCustomerInfo } = useCustomerApi();

  const { data: customerData, isFetched: isFetchedCurrentCustomer } =
    getMyCustomerInfo();

  const currentCustomer = computed<Customer | undefined>(() => {
    return customerData.value ? customerData.value : undefined;
  });

  /** Customer ID migrated from WordPress. */
  const migrationId = computed<number | null>(
    () => currentCustomer.value?.migrationId || null
  );

  return { currentCustomer, isFetchedCurrentCustomer, migrationId };
}
