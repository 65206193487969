import { format, parse } from 'date-fns';

/**
 * Formats a date to a week number.
 * @example '12'
 */
export const formatDateToWeekNumber = (date: Date) => format(date, 'I');

/**
 * Formats a year-week to a Date.
 * @example '2022-24' => Date // Monday 00:00:00 GMT+0200 of that week
 */
export const formatWeekToDate = (yearWeek: string): Date => {
  if (!yearWeek) return new Date();
  const startDate: Date = parse(
    `1.1.${yearWeek.split('-')[0]}`,
    'd.M.yyyy',
    new Date()
  );
  const week: string = yearWeek.split('-')[1];
  return parse(week, 'w', startDate);
};

export const formatDateUtc = (date: Date) => date.toISOString().split('T')[0];
