import type {
  CustobarEvent,
  CustobarInstance,
  WindowWithCustobar,
} from '@/types';
import useTrackingConsent from './useTrackingConsent';

interface CustobarComposable {
  checkCustobarStatus: () => void;
  sendPageView: () => void;
}
const CUSTOBAR_SCRIPT_ID = 'custobar-js';

/**
 * Custobar tracking script adapted for Nuxt.
 * @see {@link https://www.custobar.com/docs/api/tracking/ Custobar.js tracking script}
 */
export default function useCustobar(): CustobarComposable {
  const { hasMarketingConsent } = useTrackingConsent();

  /**
   * Company token for Custobar instance.
   * @see {@link https://ruokaboksi.custobar.com/settings/tracking Token for production}
   * @see {@link https://ruokaboksi-staging.custobar.com/settings/tracking Token for staging}
   */
  const custobarCompanyToken = useRuntimeConfig().public.custobarCompanyToken;

  const { migrationId } = useCurrentCustomer();
  const { currentUserUid } = useCurrentUser();

  /**
   * Customer ID used for Custobar events.
   * @returns {string|undefined} Migration ID from WordPress or current user UID from Firebase.
   */
  const custobarCustomerId = computed<string | undefined>(
    () => migrationId.value?.toString() || currentUserUid.value || undefined
  );

  /**
   * Contents of script tag that should be injected in the <head> section of the page.
   * @see {@link https://nuxt.com/docs/api/composables/use-head useHead()} documentation
   */
  const custobarScript = computed<string>(() =>
    custobarCompanyToken
      ? `(function(c,u,s,t,o,b,a,r) {var e;c[o]=[];c[b]=a;e=u.createElement(s);
    r=u.getElementsByTagName(s)[0];e.async=1;e.src=t;
    r.parentNode.insertBefore(e,r);})(window,document,'script',
    'https://api.custobar.com/js/v1/custobar.js',
    'cstbr','cstbrConfig',{"_companyToken":"${custobarCompanyToken}"});`
      : ''
  );

  /** Custobar instance. */
  const custobar = computed<CustobarInstance | undefined>(
    () => (window as WindowWithCustobar).cstbr || undefined
  );

  const checkCustobarStatus = (): void => {
    const existingScript = document.getElementById(
      CUSTOBAR_SCRIPT_ID
    ) as HTMLScriptElement | null;
    if (hasMarketingConsent.value && !existingScript && custobarScript.value) {
      const scriptElement = document.createElement('script');
      scriptElement.setAttribute('id', CUSTOBAR_SCRIPT_ID);
      scriptElement.innerHTML = custobarScript.value;
      document.head.appendChild(scriptElement);
      nextTick(() => sendPageView);
    } else if (!hasMarketingConsent.value && existingScript) {
      existingScript.remove();
    }
  };

  /** Sends Custobar events. */
  const sendCustobarEvent = (event: CustobarEvent): void => {
    if (!hasMarketingConsent.value) return;
    if (!custobar.value || !custobarCustomerId.value || !event) return;
    if (!('setCustomerId' in custobar.value)) {
      return;
    }

    custobar.value.setCustomerId(custobarCustomerId.value);
    custobar.value.push(event);
  };

  /** Sends page view event to Custobar. */
  const sendPageView = (): void => {
    if (!hasMarketingConsent.value) return;
    const event: CustobarEvent = { path: window.location.href, type: 'BROWSE' };
    sendCustobarEvent(event);
  };

  return {
    checkCustobarStatus,
    sendPageView,
  };
}
