import type { Campaign } from '@ruokaboksi/api-client';
import { Week, isCampaignActive } from '@ruokaboksi/utils';

/**
 * Campaigns linked to current customer's subscription.
 */
export default function useCustomerCampaigns() {
  const { getLinkedSubscriptionCampaign } = useCampaignsApi();
  const { subscriptionId, subscription, isSubscriptionSuspended } =
    useCustomerSubscriptions();
  const { selectedDeliveryWeek } = useDeliveryWeeks();

  const {
    data: campaignLink,
    isFetched: isFetchedCustomerCampaigns,
    isLoading: isLoadingCustomerCampaigns,
  } = getLinkedSubscriptionCampaign(subscriptionId);

  /** Campaign linked to current customer's subscription. */
  const campaign = computed<Campaign | undefined>(
    () => campaignLink.value?.campaign
  );

  /** ID of current campaign linked to the customer's subscription. */
  const campaignId = computed<string>(
    () => campaignLink.value?.campaignId || ''
  );

  /** Start time of current campaign. */
  const campaignStartWeek = computed<Week | null>(() => {
    if (
      !campaignLink.value?.startIsoYear ||
      !subscription.value?.defaultDeliverySlot
    ) {
      return null;
    }
    return new Week(
      campaignLink.value?.startIsoYear,
      campaignLink.value?.startIsoWeek,
      subscription.value?.defaultDeliverySlot
    );
  });

  const isCampaignPromotable = computed<boolean>(() => {
    if (!campaignLink.value || !subscription.value) return false;

    const now = new Date();
    const createdAtTime = new Date(campaignLink.value.createdAt);
    const subscriptionUpdatedAtTime = new Date(subscription.value.updatedAt);
    const subscriptionCreatedAtTime = new Date(subscription.value.createdAt);

    const isCampaignOldEnough =
      (now.getTime() - createdAtTime.getTime()) / 1000 / 60 > 120; // Campaign has been activated less than 120 minutes ago

    const isSubscriptionOldEnough =
      (now.getTime() - subscriptionCreatedAtTime.getTime()) /
        1000 /
        60 /
        60 /
        24 >
      7; // Subscription is at least a week old

    const isSubscriptionStaleEnough =
      (now.getTime() - subscriptionUpdatedAtTime.getTime()) / 1000 / 60 > 15; // Subscription has not been updated in the past 15 minutes

    const isCampaignActiveNextWeek = isCampaignActive(
      campaignLink.value,
      Week.fromFirstFuturePaymentDate(subscription.value.defaultDeliverySlot)
    );

    const isCampaignLongEnough = campaignLink.value.campaign.durationWeeks > 1;

    return (
      isCampaignActiveNextWeek &&
      isCampaignOldEnough &&
      isSubscriptionOldEnough &&
      isSubscriptionStaleEnough &&
      isCampaignLongEnough &&
      isSubscriptionSuspended.value
    );
  });

  /** Type of current campaign. */
  const campaignType = computed<Campaign['campaignType'] | undefined>(
    () => campaignLink.value?.campaign.campaignType
  );

  /**
   * @returns `true` the  current customer has a campaign
   * attached to their subscription.
   */
  const hasCampaign = computed<boolean>(() => {
    if (!campaignLink.value || !subscription.value) return false;

    if (selectedDeliveryWeek.value) {
      return isCampaignActive(
        campaignLink.value,
        Week.fromYyyyWww(
          selectedDeliveryWeek.value.weekString,
          subscription.value.defaultDeliverySlot
        )
      );
    }

    return isCampaignActive(
      campaignLink.value,
      Week.fromFirstFuturePaymentDate(subscription.value.defaultDeliverySlot)
    );
  });

  return {
    campaign,
    campaignLink,
    campaignId,
    campaignStartWeek,
    campaignType,
    hasCampaign,
    isFetchedCustomerCampaigns,
    isLoadingCustomerCampaigns,
    isCampaignPromotable,
  };
}
