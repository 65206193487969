import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 36 36"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M31.816 17.598c0 7.853-6.365 14.218-14.218 14.218s-14.22-6.365-14.22-14.218c0-3.926 1.594-7.483 4.165-10.055A14.18 14.18 0 0 1 17.598 3.38c7.853 0 14.218 6.366 14.218 14.219M18.691 29.58c6.133-.553 10.938-5.706 10.938-11.982 0-6.645-5.387-12.032-12.031-12.032a11.98 11.98 0 0 0-7.7 2.786l8.793 8.793zm-2.187 0V18.69H5.615c.52 5.772 5.117 10.369 10.889 10.889M5.615 16.504h9.342L8.352 9.899a11.97 11.97 0 0 0-2.737 6.605"
    }, null, -1)
  ])))
}
export default { render: render }