// eslint-disable-next-line import/no-unresolved
import { useRegisterSW } from 'virtual:pwa-register/vue';

export default defineNuxtPlugin(() => {
  const removeServiceWorkerCache = async () => {
    if ('caches' in window) {
      const cacheKeys = await window.caches.keys();

      for (const x of cacheKeys) {
        if (x.includes('workbox-precache-v2-')) {
          await window.caches.delete(x);
        }
      }
    }
  };

  const serviceWorker = useRegisterSW({
    immediate: true,
  });

  return {
    provide: {
      serviceWorker: { ...serviceWorker, removeServiceWorkerCache },
    },
  };
});
