import type { RecipeInstructions } from '@ruokaboksi/api-client';
import type { PropType, VNode } from 'vue';

export default defineComponent({
  name: 'IngredientLists',
  props: {
    ingredientLists: {
      default: [],
      type: Array as PropType<RecipeInstructions['ingredientLists']>,
    },
  },
  render(): VNode | null {
    if (!this.ingredientLists.length) return null;
    return (
      <dl class="pl-[2.3rem]">
        {this.ingredientLists.map((list) => (
          <div class="mb-4 last:mb-0 text-base ">
            <dt class="font-semibold">{list.title}</dt>
            {list.ingredients.map((ingredient) => (
              <dd class="flex">
                {ingredient.amount && (
                  <span class="mr-1">{ingredient.amount}</span>
                )}
                <span class="lowercase">{formatTitle(ingredient.title)}</span>
              </dd>
            ))}
          </div>
        ))}
      </dl>
    );
  },
});
