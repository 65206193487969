import type { Recipe } from '@ruokaboksi/api-client';
import { storeToRefs } from 'pinia';

/** Closes the modal when the user hits escape. */
const closeModalOnEscape = (event: KeyboardEvent): void => {
  const modalStore = useModalStore();
  const { isProductModalVisible } = storeToRefs(modalStore);

  if (!isProductModalVisible.value || event.code !== 'Escape') return;

  event.preventDefault();
  modalStore.closeModal(ModalType.Product);
};

/**
 * Closes the modal window when the user clicks on a specific element.
 */
const closeModalOnClick = (event: Event): void => {
  const modalStore = useModalStore();
  event.preventDefault();
  modalStore.closeModal(ModalType.Product);
};

/**
 * Closes the modal window when the user clicks somewhere outside the content area of the product modal.
 */
const closeModalOnBackdrop = (event: Event): void => {
  const target = event.target as HTMLElement;

  if (!target.classList.contains('product-modal')) return;

  const modalStore = useModalStore();
  modalStore.closeModal(ModalType.Product);
};

/** Fields required for image labels. */
const requiredImageFields = (
  label:
    | Recipe['allergens'][number]
    | Recipe['diets'][number]
    | Recipe['recipeLabels'][number]
): boolean => !!(label.image?.sizes?.thumbnail && label.title);

export {
  closeModalOnBackdrop,
  closeModalOnClick,
  closeModalOnEscape,
  requiredImageFields,
};
