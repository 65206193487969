import type { VNode } from 'vue';
import { defineComponent } from 'vue';
import './Details.css';

export default defineComponent({
  name: 'Details',
  props: {
    open: {
      default: undefined,
      type: Boolean,
    },
  },
  setup() {
    const details = ref<HTMLDetailsElement | null>(null);

    const handleOnClick = (_event: Event): void => {
      if (!details.value) return;
      if (details.value?.hasAttribute('open')) return;
      const behavior = window.matchMedia('(prefers-reduced-motion: reduce)')
        .matches
        ? 'auto'
        : 'smooth';
      details.value.scrollIntoView({ behavior });
    };
    return { details, handleOnClick };
  },
  render(): VNode {
    return (
      <details
        class="details"
        onClick={this.handleOnClick}
        open={this.open}
        ref="details"
      >
        {this.$slots.summary?.()}
        <div class="details-content">{this.$slots.default?.()}</div>
      </details>
    );
  },
});
