import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgoApplePayMark, LazySvgoGooglePayMark, LazySvgoIcon123, LazySvgoIconAlert, LazySvgoIconArrowLeft, LazySvgoIconArrowRight, LazySvgoIconBenefit, LazySvgoIconBoxAdd, LazySvgoIconBox, LazySvgoIconCalendar, LazySvgoIconCampaign, LazySvgoIconCancelAlt, LazySvgoIconCancel, LazySvgoIconChartPieR, LazySvgoIconCheck, LazySvgoIconCheckmark, LazySvgoIconChevronDown, LazySvgoIconChevronRight, LazySvgoIconChevronUp, LazySvgoIconCircleArrowLeft, LazySvgoIconCircleArrowRight, LazySvgoIconCircle, LazySvgoIconClockHistory, LazySvgoIconClose, LazySvgoIconContactSupport, LazySvgoIconCredit, LazySvgoIconCustomers, LazySvgoIconCutlery, LazySvgoIconDeliveries, LazySvgoIconDeliveryBox, LazySvgoIconDinnerDining, LazySvgoIconDocumentScanner, LazySvgoIconDots, LazySvgoIconDownload, LazySvgoIconEmail, LazySvgoIconEyeSlash, LazySvgoIconEye, LazySvgoIconHeart, LazySvgoIconHistory, LazySvgoIconHome, LazySvgoIconInfo, LazySvgoIconKokkiklubiBox, LazySvgoIconKokkiklubiChecked, LazySvgoIconKokkiklubiWhite, LazySvgoIconKokkiklubi, LazySvgoIconLocation, LazySvgoIconLockAlt, LazySvgoIconLock, LazySvgoIconLocked, LazySvgoIconMenu, LazySvgoIconMinus, LazySvgoIconOrders, LazySvgoIconPause, LazySvgoIconPayment, LazySvgoIconPeopleOutline, LazySvgoIconPeople, LazySvgoIconPersonOutline, LazySvgoIconPlay, LazySvgoIconPlusCircle, LazySvgoIconPlus, LazySvgoIconPowerSettings, LazySvgoIconSearch, LazySvgoIconSettings, LazySvgoIconShare, LazySvgoIconSignIn, LazySvgoIconSignOut, LazySvgoIconStar, LazySvgoIconSupport, LazySvgoIconTipsAndUpdates, LazySvgoIconTrash, LazySvgoIconUnlocked } from '#components'
const lazyGlobalComponents = [
  ["SvgoApplePayMark", LazySvgoApplePayMark],
["SvgoGooglePayMark", LazySvgoGooglePayMark],
["SvgoIcon123", LazySvgoIcon123],
["SvgoIconAlert", LazySvgoIconAlert],
["SvgoIconArrowLeft", LazySvgoIconArrowLeft],
["SvgoIconArrowRight", LazySvgoIconArrowRight],
["SvgoIconBenefit", LazySvgoIconBenefit],
["SvgoIconBoxAdd", LazySvgoIconBoxAdd],
["SvgoIconBox", LazySvgoIconBox],
["SvgoIconCalendar", LazySvgoIconCalendar],
["SvgoIconCampaign", LazySvgoIconCampaign],
["SvgoIconCancelAlt", LazySvgoIconCancelAlt],
["SvgoIconCancel", LazySvgoIconCancel],
["SvgoIconChartPieR", LazySvgoIconChartPieR],
["SvgoIconCheck", LazySvgoIconCheck],
["SvgoIconCheckmark", LazySvgoIconCheckmark],
["SvgoIconChevronDown", LazySvgoIconChevronDown],
["SvgoIconChevronRight", LazySvgoIconChevronRight],
["SvgoIconChevronUp", LazySvgoIconChevronUp],
["SvgoIconCircleArrowLeft", LazySvgoIconCircleArrowLeft],
["SvgoIconCircleArrowRight", LazySvgoIconCircleArrowRight],
["SvgoIconCircle", LazySvgoIconCircle],
["SvgoIconClockHistory", LazySvgoIconClockHistory],
["SvgoIconClose", LazySvgoIconClose],
["SvgoIconContactSupport", LazySvgoIconContactSupport],
["SvgoIconCredit", LazySvgoIconCredit],
["SvgoIconCustomers", LazySvgoIconCustomers],
["SvgoIconCutlery", LazySvgoIconCutlery],
["SvgoIconDeliveries", LazySvgoIconDeliveries],
["SvgoIconDeliveryBox", LazySvgoIconDeliveryBox],
["SvgoIconDinnerDining", LazySvgoIconDinnerDining],
["SvgoIconDocumentScanner", LazySvgoIconDocumentScanner],
["SvgoIconDots", LazySvgoIconDots],
["SvgoIconDownload", LazySvgoIconDownload],
["SvgoIconEmail", LazySvgoIconEmail],
["SvgoIconEyeSlash", LazySvgoIconEyeSlash],
["SvgoIconEye", LazySvgoIconEye],
["SvgoIconHeart", LazySvgoIconHeart],
["SvgoIconHistory", LazySvgoIconHistory],
["SvgoIconHome", LazySvgoIconHome],
["SvgoIconInfo", LazySvgoIconInfo],
["SvgoIconKokkiklubiBox", LazySvgoIconKokkiklubiBox],
["SvgoIconKokkiklubiChecked", LazySvgoIconKokkiklubiChecked],
["SvgoIconKokkiklubiWhite", LazySvgoIconKokkiklubiWhite],
["SvgoIconKokkiklubi", LazySvgoIconKokkiklubi],
["SvgoIconLocation", LazySvgoIconLocation],
["SvgoIconLockAlt", LazySvgoIconLockAlt],
["SvgoIconLock", LazySvgoIconLock],
["SvgoIconLocked", LazySvgoIconLocked],
["SvgoIconMenu", LazySvgoIconMenu],
["SvgoIconMinus", LazySvgoIconMinus],
["SvgoIconOrders", LazySvgoIconOrders],
["SvgoIconPause", LazySvgoIconPause],
["SvgoIconPayment", LazySvgoIconPayment],
["SvgoIconPeopleOutline", LazySvgoIconPeopleOutline],
["SvgoIconPeople", LazySvgoIconPeople],
["SvgoIconPersonOutline", LazySvgoIconPersonOutline],
["SvgoIconPlay", LazySvgoIconPlay],
["SvgoIconPlusCircle", LazySvgoIconPlusCircle],
["SvgoIconPlus", LazySvgoIconPlus],
["SvgoIconPowerSettings", LazySvgoIconPowerSettings],
["SvgoIconSearch", LazySvgoIconSearch],
["SvgoIconSettings", LazySvgoIconSettings],
["SvgoIconShare", LazySvgoIconShare],
["SvgoIconSignIn", LazySvgoIconSignIn],
["SvgoIconSignOut", LazySvgoIconSignOut],
["SvgoIconStar", LazySvgoIconStar],
["SvgoIconSupport", LazySvgoIconSupport],
["SvgoIconTipsAndUpdates", LazySvgoIconTipsAndUpdates],
["SvgoIconTrash", LazySvgoIconTrash],
["SvgoIconUnlocked", LazySvgoIconUnlocked],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
