import type { Language } from '@ruokaboksi/api-client';
import { useQuery } from '@tanstack/vue-query';
import type { ComputedRef } from 'vue';
import { useApiClient } from '#imports';

const KEY_BOX_TYPE = 'boxTypeById';
const KEY_BOX_TYPES = 'allBoxTypes';

export default function useBoxTypeApi() {
  const apiClient = useApiClient();
  const locale = useCookie('locale');
  const { market } = useCurrentMarket();

  /** Gets a single box type based on id. */
  const getBoxType = (id: ComputedRef<string>) => {
    const enabled = computed<boolean>(() => Boolean(id.value && market.value));
    const queryFn = async () => {
      if (!market.value) return null;
      return await apiClient.boxTypes.retrieve({
        market: market.value,
        id: id.value,
        language: locale.value as Language,
      });
    };
    const queryKey = [KEY_BOX_TYPE, id];
    return useQuery({
      queryKey,
      queryFn,
      enabled,
      staleTime: 30 * 1000,
      refetchOnWindowFocus: false,
    });
  };

  /** Gets existing box types. */
  const getBoxTypes = () => {
    const enabled = computed<boolean>(() => Boolean(market.value));
    const queryFn = async () => {
      if (!market.value) return [];
      const response = await apiClient.boxTypes.list({
        market: market.value,
        language: locale.value as Language,
      });
      return response.items;
    };
    const queryKey = [KEY_BOX_TYPES];
    return useQuery({
      queryKey,
      queryFn,
      refetchOnWindowFocus: false,
      enabled,
    });
  };

  return {
    getBoxTypeById: getBoxType,
    listBoxTypes: getBoxTypes,
  };
}

export { useBoxTypeApi };
