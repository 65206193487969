import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 30 30"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M15.966 1.91A13 13 0 0 0 15 1.876V0a15 15 0 0 1 1.104.041zm3.757.844a13 13 0 0 0-1.847-.56l.41-1.83c.719.161 1.426.375 2.112.641zm2.569 1.332a13 13 0 0 0-.823-.507l.924-1.63a15 15 0 0 1 1.836 1.225l-1.153 1.48a13 13 0 0 0-.784-.566zm3.439 3.356q-.557-.792-1.225-1.493l1.358-1.293c.506.534.975 1.106 1.4 1.706zm1.395 2.535q-.185-.446-.402-.878l1.675-.843q.496.989.843 2.04l-1.78.587a13 13 0 0 0-.336-.906m.993 4.7a13 13 0 0 0-.187-1.921l1.847-.32c.125.725.198 1.46.217 2.195l-1.875.046zm-.245 2.884q.092-.477.152-.956l1.861.23q-.136 1.1-.43 2.166l-1.808-.5q.129-.465.225-.94m-1.785 4.46c.345-.543.649-1.113.911-1.702l1.714.76a15 15 0 0 1-1.04 1.946zm-1.808 2.26q.344-.343.657-.708l1.42 1.224q-.36.42-.751.81z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M15.006 1.875a13.125 13.125 0 1 0 9.281 22.406l1.326 1.326A15.002 15.002 0 1 1 15.006 0z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M14.688 6.25a.937.937 0 0 1 .937.938v9.768l6.09 3.48a.938.938 0 0 1-.93 1.628l-6.562-3.75a.94.94 0 0 1-.473-.814V7.188a.94.94 0 0 1 .938-.938"
    }, null, -1)
  ])))
}
export default { render: render }