import { RuokaboksiApiClient } from '@ruokaboksi/api-client';
import { useCurrentUser, useRuntimeConfig } from '#imports';

export default function useApiClient() {
  const { currentUser } = useCurrentUser();
  const { apiUrl } = useRuntimeConfig().public;

  const options = () => {
    return {
      interceptors: {
        provideToken: async () => {
          try {
            const token = await currentUser.value?.getIdToken();

            return token;
          } catch (_e) {
            return false;
          }
        },
      },
    };
  };

  const apiClient = new RuokaboksiApiClient(String(apiUrl), options());
  return apiClient;
}
