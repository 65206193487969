import MainHeading from '@/components/MainHeading';

export default defineNuxtComponent({
  name: 'ErrorGeneral',
  render(): VNode {
    return (
      <div>
        <MainHeading>{this.$t('error.general_title')}</MainHeading>
        <div class="mt-8">
          <p>{this.$t('error.general_description')}</p>
          <p class="mt-2">
            <a
              class="!text-current !no-underline hover:!underline"
              href={`tel:${this.$t('customer_support.phone_number_link')}`}
            >
              {this.$t('customer_support.phone_number')}
            </a>
          </p>
          <p class="mt-2">
            <a
              class="!text-current !no-underline hover:!underline"
              href={`mailto:${this.$t('customer_support.email_address')}`}
            >
              {this.$t('customer_support.email_address')}
            </a>
          </p>
        </div>
      </div>
    );
  },
});
