export default function useCustomerDeliveryArea() {
  const { subscription } = useCustomerSubscriptions();

  const { getDeliveryAreaForSubscription } = useDeliveriesApi();

  const { data: deliveryArea } = getDeliveryAreaForSubscription(subscription);

  return {
    deliveryArea,
  };
}
