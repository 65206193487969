import type { VNode } from 'vue';
import Notice from './Notice';

export default defineComponent({
  name: 'Notices',
  setup() {
    const noticeStore = useNoticeStore();
    const wrapperClasses = computed<Array<string | Record<string, boolean>>>(
      () => ['notice-wrapper', { visible: noticeStore.hasNotice }]
    );
    return { noticeStore, wrapperClasses };
  },
  render(): VNode {
    return (
      <aside
        aria-hidden={!this.noticeStore.hasNotice}
        class={this.wrapperClasses}
        role="alert"
      >
        {this.noticeStore.notices.map((notice) => (
          <Notice key={notice.id} notice={notice} />
        ))}
      </aside>
    );
  },
});
