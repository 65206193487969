import type {
  BoxType,
  DeliveryAddress,
  Language,
  Subscription,
} from '@ruokaboksi/api-client';
import { resolvePriceTier } from '@ruokaboksi/utils';

export const getBoxPriceTierTitle = (
  boxType: BoxType | null | undefined,
  recipeCount: number | undefined,
  fallbackText = ''
): string => {
  if (!boxType?.priceTiers || !recipeCount) {
    return boxType?.title ?? fallbackText;
  }

  const priceTier = resolvePriceTier(boxType?.priceTiers, recipeCount);
  return priceTier.title;
};

/**
 * Formats delivery addresses to human readable format.
 * @returns Ludviginkatu 3-5, 00100 Helsinki
 */
export const formatDeliveryAddress = (
  deliveryAddress: DeliveryAddress
): string => {
  return `${deliveryAddress.addressLine}, ${deliveryAddress.postalCode} ${deliveryAddress.city}`;
};

/** Adds a suffix after the nutritional value. */
const formatNutritionValue = (value: number, unit: 'g' | 'kcal'): string => {
  const roundedValue = Math.ceil(value);
  switch (unit) {
    case 'g':
      return `${roundedValue} (g)`;
    case 'kcal':
      return `${roundedValue} (kcal)`;
    default:
      return roundedValue.toString();
  }
};

/**
 * Formats titles by removing whitespace and
 * capitalizing the first letter.
 */
function formatTitle(s: string): string {
  if (!s || typeof s !== 'string') return '';
  s = s.trim();
  s = uppercaseFirstLetter(s);
  return s;
}

/** Capitalizes first letter of a string. */
function uppercaseFirstLetter(s: string): string {
  return s[0].toUpperCase() + s.slice(1);
}

export { formatNutritionValue, formatTitle, uppercaseFirstLetter };

/**
 * Default price of the subscription using a fetched box type.
 * @return Price in cents or undefined.
 */
export const formatSubscriptionDefaultPrice = (
  subscription: Subscription,
  boxType: BoxType
): number | undefined => {
  if (boxType.priceTiers === null) {
    return undefined;
  }

  const recipeCount = subscription.defaultNumberOfRecipes;
  const priceTierMatches = boxType.priceTiers.filter(
    (tier) => tier.recipeCount === recipeCount
  );
  if (priceTierMatches.length !== 1) {
    // Either 0 (no matches) or multiple (invalid tier data)
    return undefined;
  }
  return priceTierMatches[0].price;
};

/**
 * Returns ordinal number string for the given number.
 * English: 1st, 24th, 102nd etc.
 * Everything else: 1. 2. 3. etc.
 * @returns
 */
export const getOrdinalNumber = (
  number: number,
  language: Language
): string => {
  switch (language) {
    case 'fi':
      return `${number}.`;
    case 'en': {
      const suffixList = ['th', 'st', 'nd', 'rd'];
      const lastDigits = number % 100;
      return (
        number +
        (suffixList[(lastDigits - 20) % 10] ||
          suffixList[lastDigits] ||
          suffixList[0])
      );
    }
    case 'cs':
      return `${number}.`;
    default:
      return `${number}.`;
  }
};
