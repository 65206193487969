import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 35 35"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M6.285 24.712V9.061H3.717L0 11.767v2.487l3.588-2.581h.13v13.04h2.569zm7.028-11.134v-.138c0-1.352.963-2.557 2.616-2.557 1.48 0 2.568.963 2.568 2.42 0 1.364-.918 2.408-1.765 3.337l-5.816 6.445v1.627h10.456v-2.165h-6.84v-.151l3.865-4.344c1.422-1.584 2.706-2.914 2.706-4.966-.002-2.479-2.021-4.336-5.117-4.336-3.44 0-5.162 2.328-5.162 4.703v.125zm14.348 4.12h1.72c1.8 0 3.005 1.051 3.016 2.578.022 1.547-1.203 2.66-3.109 2.647-1.684-.01-2.9-.916-3.016-2.085h-2.395c.091 2.304 2.051 4.196 5.39 4.196 3.233 0 5.779-1.835 5.73-4.69-.043-2.5-2.016-3.612-3.392-3.75v-.137c1.17-.197 2.947-1.444 2.9-3.67-.056-2.304-2.04-4.059-5.16-4.037-3.28.011-5.068 1.925-5.136 4.152h2.441c.07-1.09 1.09-2.065 2.638-2.065 1.538 0 2.639.952 2.639 2.34.01 1.4-1.103 2.42-2.625 2.42H27.66z"
    }, null, -1)
  ])))
}
export default { render: render }