
// @ts-nocheck


export const localeCodes =  [
  "fi",
  "en",
  "cs"
]

export const localeLoaders = {
  "fi": [{ key: "../locales/fi.json", load: () => import("../locales/fi.json" /* webpackChunkName: "locale__app_clients_members_locales_fi_json" */), cache: true }],
  "en": [{ key: "../locales/en.json", load: () => import("../locales/en.json" /* webpackChunkName: "locale__app_clients_members_locales_en_json" */), cache: true }],
  "cs": [{ key: "../locales/cs.json", load: () => import("../locales/cs.json" /* webpackChunkName: "locale__app_clients_members_locales_cs_json" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "__i18n_config_ts_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n.config.ts",
  "locales": [
    {
      "code": "fi",
      "titleKey": "language.finnish",
      "language": "fi",
      "domain": "members.ruokaboksi.fi",
      "gtmContainerId": "GTM-PD9X9G6",
      "domainDefault": true,
      "brand": "Ruokaboksi",
      "loyaltyProgramName": "Kokkiklubi",
      "files": [
        "/app/clients/members/locales/fi.json"
      ]
    },
    {
      "code": "en",
      "language": "en",
      "titleKey": "language.english",
      "domain": "members.yummybox.cz",
      "gtmContainerId": "GTM-T5BZ4VK3",
      "brand": "Yummy",
      "loyaltyProgramName": null,
      "files": [
        "/app/clients/members/locales/en.json"
      ]
    },
    {
      "code": "cs",
      "language": "cs",
      "titleKey": "language.czech",
      "domain": "members.yummybox.cz",
      "gtmContainerId": "GTM-T5BZ4VK3",
      "domainDefault": true,
      "brand": "Yummy",
      "loyaltyProgramName": null,
      "files": [
        "/app/clients/members/locales/cs.json"
      ]
    }
  ],
  "defaultLocale": "fi",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "no_prefix",
  "lazy": true,
  "langDir": "locales",
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "fi",
    "titleKey": "language.finnish",
    "language": "fi",
    "domain": "members.ruokaboksi.fi",
    "gtmContainerId": "GTM-PD9X9G6",
    "domainDefault": true,
    "brand": "Ruokaboksi",
    "loyaltyProgramName": "Kokkiklubi",
    "files": [
      {
        "path": "/app/clients/members/locales/fi.json"
      }
    ]
  },
  {
    "code": "en",
    "language": "en",
    "titleKey": "language.english",
    "domain": "members.yummybox.cz",
    "gtmContainerId": "GTM-T5BZ4VK3",
    "brand": "Yummy",
    "loyaltyProgramName": null,
    "files": [
      {
        "path": "/app/clients/members/locales/en.json"
      }
    ]
  },
  {
    "code": "cs",
    "language": "cs",
    "titleKey": "language.czech",
    "domain": "members.yummybox.cz",
    "gtmContainerId": "GTM-T5BZ4VK3",
    "domainDefault": true,
    "brand": "Yummy",
    "loyaltyProgramName": null,
    "files": [
      {
        "path": "/app/clients/members/locales/cs.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
