import MainHeading from '@/components/MainHeading';
import { NuxtLink } from '#components';

export default defineNuxtComponent({
  name: 'ErrorNotFound',
  render(): VNode {
    return (
      <div>
        <MainHeading>{this.$t('error.not_found_heading')}</MainHeading>
        <NuxtLink to={{ name: 'index' }} class="button button-primary mt-8">
          {this.$t('error.not_found_button')}
        </NuxtLink>
      </div>
    );
  },
});
