import AvainLippuLogo from '@/assets/logos/avainlippu.svg?component';
import KokkiKlubiLogo from '@/assets/logos/kokkiklubi.svg?component';
import RuokaboksiLogo from '@/assets/logos/ruokaboksi-green.svg?component';
import RuokaboksiWhiteLogo from '@/assets/logos/ruokaboksi-white.svg?component';
import YummyLogo from '@/assets/logos/yummy-green.svg?component';
import YummyWhiteLogo from '@/assets/logos/yummy-white.svg?component';
import type { i18n } from '@/config/i18n';

export const getGreenLogo = (
  brand: (typeof i18n)['locales'][number]['brand']
) => {
  switch (brand) {
    case 'Yummy':
      return YummyLogo;
    default:
      return RuokaboksiLogo;
  }
};

export const getWhiteLogo = (
  brand: (typeof i18n)['locales'][number]['brand']
) => {
  switch (brand) {
    case 'Yummy':
      return YummyWhiteLogo;
    default:
      return RuokaboksiWhiteLogo;
  }
};

export const getLoyaltyLogo = (
  brand: (typeof i18n)['locales'][number]['brand']
) => {
  switch (brand) {
    case 'Yummy':
      return null;
    default:
      return KokkiKlubiLogo;
  }
};

export const getLoadingScreenLogo = (
  brand: (typeof i18n)['locales'][number]['brand']
) => {
  switch (brand) {
    case 'Yummy':
      return null;
    default:
      return AvainLippuLogo;
  }
};
