import type { PropType, VNode } from 'vue';

export default defineComponent({
  name: 'SanityPicture',
  props: {
    alt: {
      default: '',
      type: String as PropType<null | string | undefined>,
    },
    src: {
      required: true,
      type: String,
    },
    lazy: {
      default: false,
      type: Boolean,
    },
  },
  render(): VNode | null {
    if (!this.src) return null;
    return (
      <picture class="picture-wrapper">
        {(isSanityJpeg(this.src) || isSanityPng(this.src)) && (
          <source srcset={formatSanityWebP(this.src)} type="image/webp" />
        )}
        {isSanityPng(this.src) && (
          <source srcset={formatSanityJpeg(this.src)} type="image/jpeg" />
        )}
        <img
          alt={this.alt ?? ''}
          src={this.src}
          loading={this.lazy ? 'lazy' : undefined}
        />
      </picture>
    );
  },
});
