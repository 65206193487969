import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M10 1.667A8.326 8.326 0 0 0 1.667 10 8.326 8.326 0 0 0 10 18.333 8.326 8.326 0 0 0 18.333 10 8.326 8.326 0 0 0 10 1.667m0 15A6.676 6.676 0 0 1 3.333 10 6.676 6.676 0 0 1 10 3.333 6.676 6.676 0 0 1 16.667 10 6.676 6.676 0 0 1 10 16.667m2.992-10.834L10 8.825 7.008 5.833 5.833 7.008 8.825 10l-2.992 2.992 1.175 1.175L10 11.175l2.992 2.992 1.175-1.175L11.175 10l2.992-2.992z"
    }, null, -1)
  ])))
}
export default { render: render }