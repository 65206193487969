import LoadingEggsUrl from '@/assets/images/loading.gif?url';
import type { VNode } from 'vue';

export default defineComponent({
  name: 'LoadingEggs',
  props: {
    loadingTextKey: {
      default: '',
      type: String,
    },
  },
  render(): VNode {
    return (
      <div>
        <img
          alt={this.$t('loading.alt_text')}
          class="mx-auto mb-6 mt-24 block w-full max-w-44"
          src={LoadingEggsUrl}
        />
        <h2 class="text-center motion-safe:animate-bounce">
          {this.loadingTextKey && this.$t(this.loadingTextKey)}
        </h2>
      </div>
    );
  },
});
