import { default as index2ihl0j99XYMeta } from "/app/clients/members/pages/additional-products/index.tsx?macro=true";
import { default as indexWerIslC91tMeta } from "/app/clients/members/pages/auth-action/index.tsx?macro=true";
import { default as indexnrol9iLNCwMeta } from "/app/clients/members/pages/delivery-calendar/index.tsx?macro=true";
import { default as indexo0Se0z95OpMeta } from "/app/clients/members/pages/existing-user/index.tsx?macro=true";
import { default as index0jIaVaAvblMeta } from "/app/clients/members/pages/index.tsx?macro=true";
import { default as index1QNkk6DjQ4Meta } from "/app/clients/members/pages/more/account/index.tsx?macro=true";
import { default as indexYLej1srAvcMeta } from "/app/clients/members/pages/more/change-language/index.tsx?macro=true";
import { default as indexuAWkSny18TMeta } from "/app/clients/members/pages/more/change-password/index.tsx?macro=true";
import { default as indexRZCx0tM2L0Meta } from "/app/clients/members/pages/more/contact-details/index.tsx?macro=true";
import { default as _91id_93Sf3jSZV2H2Meta } from "/app/clients/members/pages/more/deliveries/[id].tsx?macro=true";
import { default as indexoSFmeLG1TGMeta } from "/app/clients/members/pages/more/deliveries/index.tsx?macro=true";
import { default as index2ikRhGqPsXMeta } from "/app/clients/members/pages/more/index.tsx?macro=true";
import { default as index0ibcC20vZQMeta } from "/app/clients/members/pages/more/notifications/index.tsx?macro=true";
import { default as indexDyYccipxEcMeta } from "/app/clients/members/pages/more/offers/credits/index.tsx?macro=true";
import { default as indexAQxEsBX2YZMeta } from "/app/clients/members/pages/more/offers/index.tsx?macro=true";
import { default as indexlcdHoEQkJUMeta } from "/app/clients/members/pages/more/offers/loyalty/index.tsx?macro=true";
import { default as addZQ2wUVy2U5Meta } from "/app/clients/members/pages/more/payment-methods/add.tsx?macro=true";
import { default as indexcmzxiPMYGZMeta } from "/app/clients/members/pages/more/payment-methods/index.tsx?macro=true";
import { default as indexO2nxkMjOwfMeta } from "/app/clients/members/pages/more/settings/index.tsx?macro=true";
import { default as indexu8dCVdPuzZMeta } from "/app/clients/members/pages/more/subscription/index.tsx?macro=true";
import { default as index1y624Hl0xfMeta } from "/app/clients/members/pages/more/support/index.tsx?macro=true";
import { default as index2RysPSseHiMeta } from "/app/clients/members/pages/new-password/index.tsx?macro=true";
import { default as index258pP9RZWdMeta } from "/app/clients/members/pages/no-subscription/index.tsx?macro=true";
import { default as indexXxp3MjDCYhMeta } from "/app/clients/members/pages/onboarding/add-payment-method/index.tsx?macro=true";
import { default as indexftfAjAiU5fMeta } from "/app/clients/members/pages/onboarding/confirm-address/index.tsx?macro=true";
import { default as index2mZgXlJTLqMeta } from "/app/clients/members/pages/onboarding/index.tsx?macro=true";
import { default as indexGgSjdkK5a4Meta } from "/app/clients/members/pages/onboarding/settings/index.tsx?macro=true";
import { default as indexMsl3wOyQtVMeta } from "/app/clients/members/pages/pay-delivery/index.tsx?macro=true";
import { default as index6r4o9nzbVBMeta } from "/app/clients/members/pages/payment-required/index.tsx?macro=true";
import { default as indexrLrezp2yTQMeta } from "/app/clients/members/pages/recipes/index.tsx?macro=true";
import { default as indexzBxS2aGTJLMeta } from "/app/clients/members/pages/reset-password/index.tsx?macro=true";
import { default as indexdekedXRWuQMeta } from "/app/clients/members/pages/sign-in-email/index.tsx?macro=true";
import { default as indexh7t2N6jvquMeta } from "/app/clients/members/pages/sign-in-password/index.tsx?macro=true";
import { default as indexTqHEgeQ8P7Meta } from "/app/clients/members/pages/sign-in-send-link/index.tsx?macro=true";
import { default as indexSoNT45cY4MMeta } from "/app/clients/members/pages/sign-in/index.tsx?macro=true";
import { default as indexj2ZIDoQIMUMeta } from "/app/clients/members/pages/sign-out/index.tsx?macro=true";
export default [
  {
    name: "additional-products",
    path: "/additional-products",
    meta: index2ihl0j99XYMeta || {},
    component: () => import("/app/clients/members/pages/additional-products/index.tsx")
  },
  {
    name: "auth-action",
    path: "/auth-action",
    meta: indexWerIslC91tMeta || {},
    component: () => import("/app/clients/members/pages/auth-action/index.tsx")
  },
  {
    name: "delivery-calendar",
    path: "/delivery-calendar",
    meta: indexnrol9iLNCwMeta || {},
    component: () => import("/app/clients/members/pages/delivery-calendar/index.tsx")
  },
  {
    name: "existing-user",
    path: "/existing-user",
    meta: indexo0Se0z95OpMeta || {},
    component: () => import("/app/clients/members/pages/existing-user/index.tsx")
  },
  {
    name: "index",
    path: "/",
    meta: index0jIaVaAvblMeta || {},
    component: () => import("/app/clients/members/pages/index.tsx")
  },
  {
    name: "more-account",
    path: "/more/account",
    meta: index1QNkk6DjQ4Meta || {},
    component: () => import("/app/clients/members/pages/more/account/index.tsx")
  },
  {
    name: "more-change-language",
    path: "/more/change-language",
    meta: indexYLej1srAvcMeta || {},
    component: () => import("/app/clients/members/pages/more/change-language/index.tsx")
  },
  {
    name: "more-change-password",
    path: "/more/change-password",
    meta: indexuAWkSny18TMeta || {},
    component: () => import("/app/clients/members/pages/more/change-password/index.tsx")
  },
  {
    name: "more-contact-details",
    path: "/more/contact-details",
    meta: indexRZCx0tM2L0Meta || {},
    component: () => import("/app/clients/members/pages/more/contact-details/index.tsx")
  },
  {
    name: "more-deliveries-id",
    path: "/more/deliveries/:id()",
    meta: _91id_93Sf3jSZV2H2Meta || {},
    component: () => import("/app/clients/members/pages/more/deliveries/[id].tsx")
  },
  {
    name: "more-deliveries",
    path: "/more/deliveries",
    meta: indexoSFmeLG1TGMeta || {},
    component: () => import("/app/clients/members/pages/more/deliveries/index.tsx")
  },
  {
    name: "more",
    path: "/more",
    meta: index2ikRhGqPsXMeta || {},
    component: () => import("/app/clients/members/pages/more/index.tsx")
  },
  {
    name: "more-notifications",
    path: "/more/notifications",
    meta: index0ibcC20vZQMeta || {},
    component: () => import("/app/clients/members/pages/more/notifications/index.tsx")
  },
  {
    name: "more-offers-credits",
    path: "/more/offers/credits",
    meta: indexDyYccipxEcMeta || {},
    component: () => import("/app/clients/members/pages/more/offers/credits/index.tsx")
  },
  {
    name: "more-offers",
    path: "/more/offers",
    meta: indexAQxEsBX2YZMeta || {},
    component: () => import("/app/clients/members/pages/more/offers/index.tsx")
  },
  {
    name: "more-offers-loyalty",
    path: "/more/offers/loyalty",
    meta: indexlcdHoEQkJUMeta || {},
    component: () => import("/app/clients/members/pages/more/offers/loyalty/index.tsx")
  },
  {
    name: "more-payment-methods-add",
    path: "/more/payment-methods/add",
    meta: addZQ2wUVy2U5Meta || {},
    component: () => import("/app/clients/members/pages/more/payment-methods/add.tsx")
  },
  {
    name: "more-payment-methods",
    path: "/more/payment-methods",
    meta: indexcmzxiPMYGZMeta || {},
    component: () => import("/app/clients/members/pages/more/payment-methods/index.tsx")
  },
  {
    name: "more-settings",
    path: "/more/settings",
    meta: indexO2nxkMjOwfMeta || {},
    component: () => import("/app/clients/members/pages/more/settings/index.tsx")
  },
  {
    name: "more-subscription",
    path: "/more/subscription",
    meta: indexu8dCVdPuzZMeta || {},
    component: () => import("/app/clients/members/pages/more/subscription/index.tsx")
  },
  {
    name: "more-support",
    path: "/more/support",
    meta: index1y624Hl0xfMeta || {},
    component: () => import("/app/clients/members/pages/more/support/index.tsx")
  },
  {
    name: "new-password",
    path: "/new-password",
    meta: index2RysPSseHiMeta || {},
    component: () => import("/app/clients/members/pages/new-password/index.tsx")
  },
  {
    name: "no-subscription",
    path: "/no-subscription",
    meta: index258pP9RZWdMeta || {},
    component: () => import("/app/clients/members/pages/no-subscription/index.tsx")
  },
  {
    name: "onboarding-add-payment-method",
    path: "/onboarding/add-payment-method",
    meta: indexXxp3MjDCYhMeta || {},
    component: () => import("/app/clients/members/pages/onboarding/add-payment-method/index.tsx")
  },
  {
    name: "onboarding-confirm-address",
    path: "/onboarding/confirm-address",
    meta: indexftfAjAiU5fMeta || {},
    component: () => import("/app/clients/members/pages/onboarding/confirm-address/index.tsx")
  },
  {
    name: "onboarding",
    path: "/onboarding",
    meta: index2mZgXlJTLqMeta || {},
    component: () => import("/app/clients/members/pages/onboarding/index.tsx")
  },
  {
    name: "onboarding-settings",
    path: "/onboarding/settings",
    meta: indexGgSjdkK5a4Meta || {},
    component: () => import("/app/clients/members/pages/onboarding/settings/index.tsx")
  },
  {
    name: "pay-delivery",
    path: "/pay-delivery",
    meta: indexMsl3wOyQtVMeta || {},
    component: () => import("/app/clients/members/pages/pay-delivery/index.tsx")
  },
  {
    name: "payment-required",
    path: "/payment-required",
    meta: index6r4o9nzbVBMeta || {},
    component: () => import("/app/clients/members/pages/payment-required/index.tsx")
  },
  {
    name: "recipes",
    path: "/recipes",
    meta: indexrLrezp2yTQMeta || {},
    component: () => import("/app/clients/members/pages/recipes/index.tsx")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: indexzBxS2aGTJLMeta || {},
    component: () => import("/app/clients/members/pages/reset-password/index.tsx")
  },
  {
    name: "sign-in-email",
    path: "/sign-in-email",
    meta: indexdekedXRWuQMeta || {},
    component: () => import("/app/clients/members/pages/sign-in-email/index.tsx")
  },
  {
    name: "sign-in-password",
    path: "/sign-in-password",
    meta: indexh7t2N6jvquMeta || {},
    component: () => import("/app/clients/members/pages/sign-in-password/index.tsx")
  },
  {
    name: "sign-in-send-link",
    path: "/sign-in-send-link",
    meta: indexTqHEgeQ8P7Meta || {},
    component: () => import("/app/clients/members/pages/sign-in-send-link/index.tsx")
  },
  {
    name: "sign-in",
    path: "/sign-in",
    meta: indexSoNT45cY4MMeta || {},
    component: () => import("/app/clients/members/pages/sign-in/index.tsx")
  },
  {
    name: "sign-out",
    path: "/sign-out",
    meta: indexj2ZIDoQIMUMeta || {},
    component: () => import("/app/clients/members/pages/sign-out/index.tsx")
  }
]