import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 30 30"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M28.125 26.25S30 26.25 30 24.375s-1.875-7.5-9.375-7.5-9.375 5.625-9.375 7.5 1.875 1.875 1.875 1.875zm-14.959-1.875-.041-.008c.002-.495.313-1.93 1.425-3.225 1.035-1.213 2.854-2.392 6.075-2.392s5.038 1.181 6.075 2.392c1.112 1.294 1.421 2.732 1.425 3.226l-.015.003-.026.004zm7.459-11.25a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5m5.625-3.75a5.625 5.625 0 1 1-11.25 0 5.625 5.625 0 0 1 11.25 0M13.005 17.4a11 11 0 0 0-2.306-.463q-.66-.063-1.324-.062c-7.5 0-9.375 5.625-9.375 7.5q0 1.875 1.875 1.875H9.78a4.2 4.2 0 0 1-.405-1.875c0-1.894.707-3.829 2.044-5.445a10 10 0 0 1 1.586-1.53m-3.78 1.35A10.3 10.3 0 0 0 7.5 24.375H1.875c0-.488.308-1.931 1.425-3.233 1.022-1.192 2.798-2.354 5.925-2.39zm-6.412-8.437a5.625 5.625 0 1 1 11.25 0 5.625 5.625 0 0 1-11.25 0m5.624-3.75a3.75 3.75 0 1 0 0 7.5 3.75 3.75 0 0 0 0-7.5"
    }, null, -1)
  ])))
}
export default { render: render }