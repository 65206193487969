import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 25 25"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "m15.511 12.27 8.101-8.103a2.293 2.293 0 1 0-3.24-3.24l-8.103 8.101L4.168.927a2.292 2.292 0 1 0-3.24 3.24l8.1 8.102-8.1 8.103a2.292 2.292 0 1 0 3.24 3.24l8.1-8.1 8.104 8.1a2.29 2.29 0 0 0 3.77-.724 2.29 2.29 0 0 0-.53-2.516l-8.1-8.103z"
    }, null, -1)
  ])))
}
export default { render: render }