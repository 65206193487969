import type { DeliveryWeekBase, Subscription } from '@ruokaboksi/api-client';
import { Week } from '@ruokaboksi/utils';

export type DeliveryWeekWithFormattedDays = DeliveryWeekBase & {
  weekString: string;
  weekNumberString: string;
};

export const addWeekProperties = (
  weeks: DeliveryWeekBase[],
  subscription: Subscription
): DeliveryWeekWithFormattedDays[] =>
  weeks.map((week) => {
    return {
      ...week,
      weekString: Week.fromPaymentDate(
        new Date(week.paymentDate),
        subscription.defaultDeliverySlot
      ).toYyyyWww(),
      weekNumberString: Week.fromPaymentDate(
        new Date(week.paymentDate),
        subscription.defaultDeliverySlot
      ).isoWeek.toString(),
    };
  });
