import type { Language } from '@ruokaboksi/api-client';
import { useQuery } from '@tanstack/vue-query';
import { format } from 'date-fns';
import type { ComputedRef } from 'vue';

const KEY_WEEK_CONFIGURATION_BY_DATE = 'WeekConfigurationByDate';

const KEY_RECIPE_INSTRUCTIONS = 'recipeInstructions';

/**
 * Composable for fetching information from the _Products_ service.
 */
export default function useProductsApi() {
  const apiClient = useApiClient();
  const { market } = useCurrentMarket();
  const locale = useCookie('locale');

  const getWeekConfigurationsByDate = (
    deliveryDate: ComputedRef<Date | string>
  ) => {
    const enabled = computed<boolean>(() => {
      return Boolean(deliveryDate.value && market.value);
    });
    const queryFn = async () => {
      if (!market.value) return [];
      const response = await apiClient.weekConfigurations.list({
        market: market.value,
        date: format(deliveryDate.value, 'yyyy-MM-dd'),
        language: locale.value as Language,
      });
      return response.items;
    };
    const queryKey = [KEY_WEEK_CONFIGURATION_BY_DATE, deliveryDate];
    return useQuery({
      queryKey,
      queryFn,
      enabled,
      refetchOnWindowFocus: false,
    });
  };

  /** Gets recipe instructions based on an id. */
  const getRecipeInstructions = (id: string) => {
    const enabled = computed<boolean>(() => {
      return Boolean(id && market.value);
    });
    const queryFn = async () => {
      if (!market.value) return null;
      return await apiClient.recipes.retrieveInstructions({
        market: market.value,
        recipeId: id,
        language: locale.value as Language,
      });
    };
    const queryKey = [KEY_RECIPE_INSTRUCTIONS, id];
    return useQuery({
      queryKey,
      queryFn,
      refetchOnWindowFocus: false,
      enabled,
    });
  };

  return {
    getWeekConfigurationsByDate,
    getRecipeInstructions,
  };
}
