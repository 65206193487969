import type { AdditionalProduct, Recipe } from '@ruokaboksi/api-client';
import type { PropType, VNode } from 'vue';
import './ProductPrice.css';
import { useCustomerCurrency } from '@/composables/useCustomerCurrency';

export default defineComponent({
  name: 'ProductPrice',
  props: {
    product: {
      default: null,
      type: Object as PropType<AdditionalProduct | Recipe>,
    },
    discount: {
      default: 0,
      type: Number,
    },
    quantity: {
      default: 0,
      type: Number,
      required: false,
    },
  },
  setup() {
    const { formatPrice } = useCustomerCurrency();

    return {
      formatPrice,
    };
  },
  render(): VNode | null {
    if (!this.product) return null;
    if ('price' in this.product) {
      const { price } = this.product as AdditionalProduct;
      if (!price) return null;

      const totalPrice = price * (this.quantity || 1);

      if (this.discount === 0) {
        return (
          <span class="price product-price">
            {this.formatPrice(totalPrice)}
          </span>
        );
      }
      return (
        <>
          <span class="price product-price ">
            <span class="text-rb-gray mr-1 line-through">
              {this.formatPrice(totalPrice)}
            </span>
            {this.formatPrice(totalPrice - this.discount)}
          </span>
        </>
      );
    }

    const { premiumPrice } = this.product as Recipe;
    if (!premiumPrice) return null;
    return (
      <span class="price product-price">
        + {this.formatPrice(premiumPrice)}
      </span>
    );
  },
});
