import { acceptHMRUpdate, defineStore } from 'pinia';

interface ModalState {
  hasUserInteracted: boolean;
  showZendesk: boolean;
}

/** Initial state for modal store. */
const initialModalStore: ModalState = {
  hasUserInteracted: false,
  showZendesk: false,
};

/** Store for managing modals. */
const useZendeskStore = defineStore('ZendeskStore', {
  actions: {
    setUserInteracted() {
      this.hasUserInteracted = true;
    },
    setZendeskVisible(value = true) {
      this.showZendesk = value;
    },
  },
  state: (): ModalState => ({ ...initialModalStore }),
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useZendeskStore, import.meta.hot));
}

export default useZendeskStore;
