/**
 * @returns `true` if the image originated from Sanity's CDN
 * and it's presumed to be a JPEG based on its filename.
 */
export const isSanityJpeg = (url: string): boolean =>
  /https:\/\/cdn\.sanity\.io(.*)\.jpe?g\?/i.test(url);

/**
 * @returns `true` if the image originated from Sanity's CDN
 * and it's presumed to be a PNG based on its filename.
 */
export const isSanityPng = (url: string): boolean =>
  /https:\/\/cdn\.sanity\.io(.*)\.png\?/i.test(url);

/** Adds `fm=jpg` to an URL's search params to convert it to JPEG. */
export const formatSanityJpeg = (sanityUrl: string): string => {
  const newUrl = new URL(sanityUrl);
  newUrl.searchParams.set('fm', 'jpg');
  return `${newUrl.origin}${newUrl.pathname}${newUrl.search}`;
};

/** Adds `fm=webp` to an URL's search params to convert it to WebP. */
export const formatSanityWebP = (sanityUrl: string): string => {
  const newUrl = new URL(sanityUrl);
  newUrl.searchParams.set('fm', 'webp');
  return `${newUrl.origin}${newUrl.pathname}${newUrl.search}`;
};
