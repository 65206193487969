import type { VNode } from 'vue';

export default defineComponent({
  name: 'MainContent',
  render(): VNode {
    return (
      <main class="relative mx-auto p-4 md:p-7 lg:p-10">
        {this.$slots.default?.()}
      </main>
    );
  },
});
